import kebabCase from 'lodash/kebabCase'
import React from 'react'
import { Link, graphql } from 'gatsby'
import Page from '../components/Page'
import MainContent from '../components/MainContent'
import '../assets/scss/_pages.scss'

class CategoriesRoute extends React.Component {
  render() {
    const categories = this.props.data.allMarkdownRemark.group

    return (
      <Page {...this.props}>
        <MainContent>
          <div className="categories">
            <h2>Article Categories</h2>
            <ul className="categories__list">
              {categories.map(category => (
                <li
                  key={category.fieldValue}
                  className="categories__list-item"
                >
                  <Link
                    to={`/categories/${kebabCase(
                      category.fieldValue,
                    )}/`}
                    className="categories__list-item-link"
                  >
                    {category.fieldValue} ({category.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </MainContent>
      </Page>
    )
  }
}

export default CategoriesRoute

export const pageQuery = graphql`
  query CategoriesQuery {
    ...siteQuery
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`
